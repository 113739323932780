import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
  return (
    <header id="header" className="header-main" style={{marginBottom:"60px"}}>
        <nav id="main-navbar" className="navbar navbar-default navbar-fixed-top" role="navigation"> 
          <div className="container">

            <div className="navbar-header">
              <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
        
              <Link className="navbar-brand page-scroll" to="/" style={{backgroundSize: 'cover',maxHeight:"32px",margin:"10px"}}>bzquatre</Link>
            </div>

            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul className="nav navbar-nav navbar-right">
                    <li><Link className="page-scroll" to="/">Home</Link></li>
                    <li><Link className="page-scroll" to="/about">About</Link></li>
                    <li><Link className="page-scroll" to="/services">Services</Link></li>
                    <li><Link className="page-scroll" to="/portfolio">Works</Link></li>
                    <li><Link className="page-scroll" to="/contact">Contact</Link></li>
                </ul>
            </div>
          </div>
        </nav>
    </header>
  )
}
