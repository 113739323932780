import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PageNotFound from '../pages/PageNotFound'

export default function Price() {
  const {id}=useParams()
  const [prices,setPrices]=useState([])
  useEffect(()=>{
      axios.get("api/price").then((res)=>{
            setPrices(res.data.filter((price)=>price.service==parseInt(id)))
            console.log(prices)
        })
  },[])
  return (
    <section id="prices-section" className="page">
    <div className="page-header-wrapper">
        <div className="container">
            <div className="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                <h2>Prices</h2>
                <div className="devider"></div>
                <p className="subtitle">That how much</p>
            </div>
        </div>
    </div>
<div className="extra-space-l"></div>

<div className="prices">
<div className="container">
<div className="row">

  
  {prices.map((price)=>(
  <div className={(price.popular)?"price-box col-sm-6 price-box-featured col-md-3 wow flipInY" :"price-box col-sm-6 col-md-3 wow flipInY"} data-wow-delay={price.id*2+"s"} key={price.id}>
      <div className="panel panel-default">
        <div className="panel-heading text-center">
          <i className={price.icon}></i>
          <h3>{price.title}</h3>
        </div>
        <div className="panel-body text-center">
          <p className="lead"><strong>${price.money}</strong></p>
        </div>
        <ul className="list-group text-center">
          {price.characteristic.map((item)=>(
             <li className="list-group-item">{item}</li>
          ))}
        </ul>
        <div className="panel-footer text-center">
          <a className="btn btn-default" href="#">Order Now!</a>
        </div>
      </div>										
    </div>
  ))}
  

</div> 
</div> 
</div>
<div className="extra-space-l"></div>
</section>    
  )
}
