import React from 'react'

export default function Social() {
  return (
    
    <section id="social-section">
            
    <div className="page-header-wrapper">
        <div className="container">
            <div className="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                <h2>Join Us</h2>
                <div className="devider"></div>
                <p className="subtitle">Follow us on social networks</p>
            </div>
        </div>
    </div>
    
    <div className="container">
      <ul className="social-list">
        <li> <a href="https://www.facebook.com/bzquatre" className="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.3s"><span className="rotate-box-icon"><i className="fa fa-facebook"></i></span></a></li>
        <li> <a href="https://www.instagrame.com/bzquatre_" className="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.4s"><span className="rotate-box-icon"><i className="fa fa-instagram"></i></span></a></li>
        <li> <a href="https://www.youtube.com/@bzquatre" className="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.6s"><span className="rotate-box-icon"><i className="fa fa-youtube"></i></span></a></li>
        <li> <a href="https://github.com/bzquatre/" className="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.7s"><span className="rotate-box-icon"><i className="fa fa-github"></i></span></a></li>                    
        </ul>
    </div>
    
</section>
  )
}
