import axios from 'axios'
import React, { useEffect, useState } from 'react'

export default function Cta() {
  const [file,setFile]=useState([])
    useEffect(()=>{
        
        axios.get('api/settings/').then((res)=>{
          setFile(res.data[0].file)
        })

    },[])
  return (
    <section id="cta-section">
          	<div className="cta">
            	<div className="container">
                	<div className="row">
                    
                    	<div className="col-md-9">
                        	<h1>Download Our Service Offering</h1>
                            <p>You can download our service offers and we are committed to them with our valued customers</p>
                        </div>
                        
                        <div className="col-md-3">
                        	<div className="cta-btn wow bounceInRight" data-wow-delay="0.4s">
                        <a className="btn btn-default btn-lg" href={file} target="_blank" role="button">Download</a>
                        	</div>
                        </div>
                        
                    </div> 
                </div>
            </div>
      </section>
  )
}
