import axios from 'axios';
import React, { useEffect, useState } from 'react'
import backgroundImage from "../assets/img/map-bg.jpg"
import emailjs from 'emailjs-com';
export default function Contact() {
    const [name,setName]=useState('');
    const [email,setEmail]=useState('')
    const [subject,setSubject]=useState('')
    const [message,setMessage]=useState('')
    const [settings,setSettings]=useState([])
    useEffect(()=>{
        axios.get('api/settings/').then((res)=>{
            setSettings(res.data[0])
        })

    },[])
    const submitContact=(e)=>{
        e.preventDefault();
        
        axios.post('/api/message/',{
            name:name,
            email: email,
            subject:subject,
            message:message})
          .then(function (response) {
            emailjs.sendForm('service_bnlpmvl','template_82b78gf', e.target,'CX63aEegSu1ohZyv7')
            .then((result) => {
                setName("");setEmail("");setSubject("");setMessage("")
            });
          })


      }
  return (
    <section id="contact-section" className="page text-white parallax" data-stellar-background-ratio="0.5" style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className="cover"></div>
            
                <div className="page-header-wrapper">
                    <div className="container">
                        <div className="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                            <h2>Contacts</h2>
                            <div className="devider"></div>
                            <p className="subtitle">All to contact us</p>
                        </div>
                    </div>
                </div>
                
                <div className="contact wow bounceInRight" data-wow-delay="0.4s">
                    <div className="container">
                      <div className="row">
                        
                            <div className="col-sm-6">
                                <div className="contact-info">
                                    <h4>Our Address</h4>
                                    <ul className="contact-address">
                                        <li><i className="fa fa-map-marker fa-lg"></i>&nbsp;{settings.address} </li>
                                        <li><i className="fa fa-phone"></i>&nbsp; {settings.phone}</li>
                                        <li><i className="fa fa-envelope"></i> {settings.mail}</li>
                                    </ul>
                                </div>
                            </div>
                        
                          <div className="col-sm-6">
                                <div className="contact-form">
                                  <h4>Write to us</h4>
                                    <form role="form" onSubmit={submitContact}>
                                        <div className="form-group">
                                            <input type="text" className="form-control input-lg" name='form_name' value={name} onChange={(e)=>setName(e.target.value)} placeholder="Your Name" required />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control input-lg" name='form_email' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="E-mail" required />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control input-lg" name='form_subject' value={subject} onChange={(e)=>setSubject(e.target.value)} placeholder="Subject" required />
                                        </div>
                                        <div className="form-group">
                                            <textarea className="form-control input-lg" rows="5" name='form_message' value={message} onChange={(e)=>setMessage(e.target.value)} placeholder="Message" required></textarea>
                                        </div>
                                        <button type="submit" className="btn wow bounceInRight" data-wow-delay="0.8s">Send</button>
                                    </form>
                                </div>	
                            </div>
                                                                                
                        </div> 
                    </div> 
                </div>
    </section>
  )
}
