import axios from 'axios';
import React, { useEffect, useState } from 'react'
import backgroundImage from '../assets/img/testimonial-bg.jpg'
import OwlCarousel from 'react-owl-carousel';

const Testimonial = () => {
    const [reviews,setReviews]=useState([]);
	const [options, setOptions] = useState(null);
    useEffect(()=>{
        axios.get('/api/review/').then((res)=>{
            setReviews(res.data);
        });
    },[])
    useEffect(()=>{
      setOptions({
        items: 1,
        loop: true,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true
    })
  },[reviews])

  return (
    <section id="testimonial-section">
      <div id="testimonial-trigger" className="testimonial text-white parallax" data-stellar-background-ratio="0.5" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="cover"></div>
        {/* Begin page header */}
        <div className="page-header-wrapper">
          <div className="container">
            <div className="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
              <h2>Reviews</h2>
              <div className="devider"></div>
              <p className="subtitle">What people say about us</p>
            </div>
          </div>
        </div>
        {/* End page header */}
        <div className="container">
          <div className="testimonial-inner center-block text-center">
          <OwlCarousel id="owl-testimonial" className="owl-carousel" {...options}>
            
              {reviews.map((testimonial, index) => {
                return (
                  <div className="item" key={index}>
                    <blockquote>
                      <p>"{testimonial.contient}"</p>
                      <footer><cite title="Source Title">{testimonial.title}</cite></footer>
                    </blockquote>
                  </div>
                );
              })}
          </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;