import Home from "./pages/Home.jsx";
import { useEffect } from "react";
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import "./assets/inc/bootstrap/css/bootstrap.min.css";
import "./assets/inc/animations/css/animate.min.css";
import "./assets/inc/font-awesome/css/font-awesome.min.css";
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import "./assets/css/reset.css";
import "./assets/css/style.css";
import "./assets/css/mobile.css";
import "./assets/css/skin/black.css";
import Layout from "./layouts/Layout.jsx";
import About from "./components/About.jsx"; 
import Services from "./components/Services.jsx";
import Price from './components/Price.jsx'
import PageNotFound from "./pages/PageNotFound.jsx";
import Contact from "./components/Contact.jsx";
import Portfolio from "./components/Portfolio.jsx";
function App() {
  useEffect(()=>{
    let script = document.createElement("script");
    script.src = "/assets/js/theme.js";
    script.async = true;
    document.body.appendChild(script);
  })
  function External() {
      window.location.href = "https://bzquatre.pythonanywhere.com/";
      return null;
    }
  return (
    <>
      <Router>
        <Layout>
        <Routes>
                <Route exact path='/' element={< Home />}></Route>
               <Route path='/admin' element={<External/>}></Route>
               <Route path="/about" element={<About/>}></Route>
               <Route path="/services" element={<Services/>}></Route>
               <Route path="/portfolio" element={<Portfolio/>}></Route>
               <Route path="/contact" element={<Contact/>}></Route>
               <Route path="/services/:id" element={<Price/>}></Route>
               <Route  path="*" element = {<PageNotFound/>} ></Route>
        </Routes>
        </Layout>
      </Router>
    </>
  );
}

export default App;
