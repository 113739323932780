import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function Portfolio() {
  const [works, setWorks] = useState([]);
  const [typs, setTyps] = useState([]);
  const [filter, setFilter] = useState('');

  const handleChange = (event) => {
    setFilter(event.target.getAttribute('data-filter'));
  };

  useEffect(() => {
    axios.get('api/work/').then((res) => {
      let uniqueTypes = res.data.reduce((types, works) => {
        if (!types.has(works.type)) {
          types.add(works.type);
        }
        return types;
      }, new Set());
      setTyps(Array.from(uniqueTypes));
      setWorks(res.data);
    });
  }, []);

  return (
    <section id="portfolio-section" className="page bg-style1">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="portfolio">
              <div className="page-header-wrapper">
                <div className="container">
                  <div className="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                    <h2>Our Works</h2>
                    <div className="devider"></div>
                    <p className="subtitle">What we are proud of</p>
                  </div>
                </div>
              </div>
              <div className="portfoloi_content_area">
                <div className="portfolio_menu" id="filters">
                  <ul>
                    <li className={filter === '' ? 'active_prot_menu' : ''}>
                      <a href="#porfolio_menu" onClick={handleChange} data-filter="">
                        all
                      </a>
                    </li>
                    {typs.map((typ, index) => (
                      <li key={index} className={filter === typ ? 'active_prot_menu' : ''}>
                        <a href="#porfolio_menu" onClick={handleChange} data-filter={typ}>
                          {typ}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="portfolio_content">
                  <div className="row" id="portfolio">
                    {works
                      .filter((item) => item.type.toLowerCase() === filter.toLowerCase() || filter === '')
                      .map((item, index) => (
                        <div className="col-xs-12 col-sm-4 appsDevelopment" key={index}>
                          <div className="portfolio_single_content">
                            <img src={item.photo} alt={item.title} />
                            <div>
                              <a href={item.url}>{item.title}</a>
                              <span>{item.subtitle}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}