import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Scrolltotop from '../components/Scrolltotop'
export default function Layout({children}) {
  return (
    <>
    <Header/>
    {children}
    <Footer/>
    <Scrolltotop/>
    </>
  )
}
