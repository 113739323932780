import React, { useEffect, useState } from 'react'
import axios from 'axios'
export default function Footer() {
    const [reviews,setReviews]=useState([])
    const [email,setEmail]=useState('')
  const submitSubscribe=(e)=>{
    e.preventDefault();
    axios.post('/api/subscribe/',{email: email})
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(()=>{
    axios.get('/api/review/').then((res)=>{
        setReviews(res.data);
    })
  },[])
  return (
    <footer className="text-off-white">
      
          <div className="footer-top">
            <div className="container">
                <div className="row wow bounceInLeft" data-wow-delay="0.4s">

                      <div className="col-sm-6 col-md-4">
                        <h4>Useful Links</h4>
                          <ul className="imp-links">
                            <li><a href="">About</a></li>
                            <li><a href="">Services</a></li>
                          </ul>
                      </div>
                  
                    <div className="col-sm-6 col-md-4">
                          <h4>Subscribe</h4>
                        <div id="footer_signup">
                              <div id="email">
                                  <form id="subscribe" onSubmit={submitSubscribe}>
                                      <input type="email" placeholder="Enter email address" name="email" value={email} onChange={(e)=>setEmail(e.target.value)} id="address" data-validate="validate(required, email)"/>
                                      <button type="submit">Submit</button>
                                      <span id="result" className="section-description"></span>
                                  </form> 
                              </div>
                          </div> 
                          <p>In order to inform you of all our offers and developments and not to miss opportunities, please subscribe</p> 
                      </div>

                      <div className="col-sm-12 col-md-4">
                          <h4>Recent Tweets</h4>
                          {
                            
                          reviews.slice(0,2).map((review,index)=>{
                            return(
                                <div className="single-tweet" key={index}>
                                    <div className="tweet-content">
                                        <span>{review.title}</span> 
                                       {review.contient}
                                    </div>
                                   
                                </div>
                            )
                          })}
                          
                      </div>
                      
                  </div> 
              </div>
          </div>
          
          <div className="footer">
              <div className="container text-center wow fadeIn" data-wow-delay="0.4s">
                  <p className="copyright">Copyright &copy; 2023  Developed by <a href="http://cv.bzquatre.me/" className="theme-author">zakaria bouzegzeg</a></p>
              </div>
          </div>

      </footer>
  )
}
