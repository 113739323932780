import React from 'react'
import { Link } from "react-router-dom";

export default  function PageNotFound (){
  return(
    <div style={{ textAlign: "center" ,marginTop:"50px"}}>
      <h1>404 - Page Not Found</h1>
      <p>Oops, the page you're looking for doesn't exist.</p>
      <Link to="/">Return to Homepage</Link>
    </div>
  );}