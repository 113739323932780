import axios from 'axios'
import React, { useEffect, useState } from 'react'
import backgroundImage from "../assets/img/counter-bg.jpg"

export default function Counter() {
  const [counterups,setCounterUps]=useState([])
  useEffect(()=>{
      axios.get('api/counterup/').then((res)=>{
          setCounterUps(res.data)
      })
    },[])
  return (
    <section id="counter-section">                					
          <div id="counter-up-trigger" className="counter-up text-white parallax" data-stellar-background-ratio="0.5" style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className="cover"></div>
                      <div className="page-header-wrapper">
                          <div className="container">
                              <div className="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                                  <h2>Some Fun Facts</h2>
                                  <div className="devider"></div>
                                  <p className="subtitle">Before anyone is not told</p>
                              </div>
                          </div>
                      </div>
            <div className="container">

              <div className="row">
                {counterups.map((counterup,index)=>{return(
                  <div className="fact text-center col-md-3 col-sm-6" key={index}>
                  <div className="fact-inner">
                    <i className={counterup.icon}></i>
                                      <div className="extra-space-l"></div>
                    <span className="counter">{counterup.count}</span>
                    <p className="lead">{counterup.title}</p>
                  </div>
                </div>
                )
                })}

              </div>
              
            </div> 
          </div>
      </section>
  )
}
