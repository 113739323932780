import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
export default function Services() {
    const [services,setServices]=useState([])
    useEffect(()=>{
        axios.get('api/service/').then((res)=>{
            setServices(res.data)
        })
    },[])
  return (
    <section id="services-section" className="page text-center">

          <div className="page-header-wrapper">
              <div className="container">
                  <div className="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                      <h2>Services</h2>
                      <div className="devider"></div>
                      <p className="subtitle">what we really know how</p>
                  </div>
              </div>
          </div>
          <div className="rotate-box-2-wrapper">
              <div className="container">
                  <div className="row">
                    {services.map((service,index)=>(
                        <div className="col-md-3 col-sm-6" key={index}>
                          <Link to={"/services/"+service.id} className="rotate-box-2 square-icon text-center wow zoomIn" data-wow-delay={service.id+"s"}>
                              <span className="rotate-box-icon"><i className={service.icon}></i></span>
                              <div className="rotate-box-info">
                                  <h4>{service.title}</h4>
                                  <p>{service.content}</p>
                              </div>
                          </Link>
                      </div>
                    ))}                      
                  </div>
              </div> 
                           
          </div>
    </section>  
  )
}
