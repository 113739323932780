import axios from 'axios'
import React, { useEffect, useState } from 'react'
import backgroundImage from '../assets/img/slider-bg.jpg'
import OwlCarousel from 'react-owl-carousel';

const Carousel = () => {
    const [headers,setHeaders]=useState([])
	const [options, setOptions] = useState(null);
	useEffect(()=>{
        axios.get('api/headers/').then((res)=>{
            setHeaders(res.data)
        });
    },[])
    useEffect(()=>{
        setOptions({
            items: 1,
            autoplay: true,
            loop: true,
            dots: false,
            nav: true,
            navText: [
                "<i class='fa fa-angle-left'></i>",
                "<i class='fa fa-angle-right'></i>"
            ]
        })
    },[headers])

    return (
        <section id="text-carousel-intro-section" className="parallax" data-stellar-background-ratio="0.5" style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className="container">
                <div className="caption text-center text-white" data-stellar-ratio="0.7">
                    <OwlCarousel className="owl-carousel" {...options}>
                        {headers.map((header, index) => {
                            return (
                                <div className="item" key={index}>
                                    <h1>{header.title}</h1>
                                    <p>{header.subtitle}</p>
                                    <div className="extra-space-l"></div>
                                    <a className="btn btn-blank" href={header.url} target="_blank" role="button">View More!</a>
                                </div>
                            );
                        })}
                    </OwlCarousel>
                </div>
            </div>
        </section>
    );
};

export default Carousel;
