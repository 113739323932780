import axios from 'axios'
import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';

export default function Partners() {
    const [partenrs,setPartenrs]=useState([]);    
	const [options, setOptions] = useState(null);
    useEffect(()=>{
        axios.get('api/partner/').then((res)=>{
            setPartenrs(res.data)
        });

    },[])
    useEffect(()=>{
        setOptions({
            items: 5,
            loop: true,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayTimeout: 1000,
            autoplayHoverPause: true
        })

    },[partenrs])
  return (
    <section id="partners-section">
            <div className="page-header-wrapper">
                <div className="container">
                    <div className="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                        <h2>Our Partners</h2>
                        <div className="devider"></div>
                        <p className="subtitle">Those who trust us</p>
                    </div>
                </div>
            </div>
            <div className="container">
                <OwlCarousel className="owl-carousel" {...options}>
                    {partenrs.map((partner)=>{return(<img key={partner.id} src={partner.photo} />)})}
                </OwlCarousel>
            </div>
    </section> 
  )
}
