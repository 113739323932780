import axios from 'axios'
import React, { useEffect, useState } from 'react'

export default function About() {
    const [skills,setSkills]=useState([])
    const [settings,setSettings]=useState([])
    useEffect(()=>{
        axios.get('api/skills/').then((res)=>{
            setSkills(res.data)
        })
        axios.get('api/settings/').then((res)=>{
            setSettings(res.data[0])
        })

    },[])
       
  return (
    <section id="about-section" className="page bg-style1">
                <div className="page-header-wrapper">
                    <div className="container">
                        <div className="page-header text-center wow fadeInUp" data-wow-delay="0.3s">
                            <h2>About</h2>
                            <div className="devider"></div>
                            <p className="subtitle">little information</p>
                        </div>
                    </div>
                </div>
                <div className="rotate-box-1-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <a href="#" className="rotate-box-1 square-icon wow zoomIn" data-wow-delay="0">
                                    <span className="rotate-box-icon"><i className="fa fa-users"></i></span>
                                    <div className="rotate-box-info">
                                        <h4>Who We Are?</h4>
                                        <p>{settings.whoweare}</p>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <a href="#" className="rotate-box-1 square-icon wow zoomIn" data-wow-delay="0.2s">
                                    <span className="rotate-box-icon"><i className="fa fa-diamond"></i></span>
                                    <div className="rotate-box-info">
                                        <h4>What We Do?</h4>
                                        <p>{settings.whatwedo}</p>
                                    </div>
                                </a>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <a href="#" className="rotate-box-1 square-icon wow zoomIn" data-wow-delay="0.4s">
                                    <span className="rotate-box-icon"><i className="fa fa-heart"></i></span>
                                    <div className="rotate-box-info">
                                        <h4>Why We Do It?</h4>
                                        <p>{settings.whywedoit}</p>
                                    </div>
                                </a>
                            </div>
                            
                            <div className="col-md-3 col-sm-6">
                                <a href="#" className="rotate-box-1 square-icon wow zoomIn" data-wow-delay="0.6s">
                                    <span className="rotate-box-icon"><i className="fa fa-clock-o"></i></span>
                                    <div className="rotate-box-info">
                                        <h4>Since When?</h4>
                                        <p>{settings.sincewhen}</p>
                                    </div>
                                </a>
                            </div>
                            
                        </div> 
                    </div> 
                </div>
                <div className="extra-space-l"></div>
                <div className="page-header-wrapper">
                    <div className="container">
                        <div className="page-header text-center wow fadeInUp" data-wow-delay="0.3s">
                            <h4>Our Skills</h4>
                        </div>
                    </div>
                </div>
                <div className="our-skills">
                	<div className="container">
                    	<div className="row">
                            {skills.map((skill,index)=>(
                                <div className="col-sm-6" key={index}>
                                <div className="skill-bar wow slideInLeft" data-wow-delay={skill.id*0.2+"s"}>
                                    <div className="progress-lebel">
                                        <h6>{skill.title}</h6>
                                    </div>
                                    <div className="progress">
                                      <div className="progress-bar" role="progressbar" aria-valuenow={skill.progress} aria-valuemin="0" aria-valuemax="100" style={{width:skill.progress+"%"}}>
                                      </div>
                                    </div>
                                </div>
                            </div> 
                            ))}
                        	
                           
                            
                        </div> 
                    </div> 
                </div>
    </section>
  )
}
