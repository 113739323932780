import React from 'react'
import About from "../components/About";
import Carousel from "../components/Carousel";
import Counter from "../components/Counter";
import Partners from "../components/Partners";
import Portfolio from "../components/Portfolio";
import Services from "../components/Services";
import Social from "../components/Social";
import Team from "../components/Team";
import Testimonial from "../components/Testimonial";
import Cta from "../components/Cta";
export default function Home() {
  return (<>
        <Carousel/>
        <About/>
        <Cta/>
        <Services/>
        <Testimonial/>
        <Portfolio/> 
        <Counter/>
        <Team/>
        <Partners/>  
        <Social/>
        </>
  )
}
