import axios from 'axios'
import React, { useEffect, useState } from 'react'

export default function Team() {
    const [teams,setTeams]=useState([])
    useEffect(()=>{
        axios.get('api/team/').then((res)=>{
            setTeams(res.data)
            
        })

    },[])
  return (
    <section id="team-section" className="page">
            <div className="page-header-wrapper">
                <div className="container">
                    <div className="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                        <h2>Our Team</h2>
                        <div className="devider"></div>
                        <p className="subtitle">Meat our experts</p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="team-items">
                        {teams.map((team,index)=>{return(
                                <div className="col-md-2" key={index}>
                                <div className="team-container wow bounceIn" data-wow-delay={"0."+index}>
                                    <div className="team-item">
                                        <div className="team-triangle">
                                            <div className="content">
                                                <img src={team.photo} alt={team.name}/>
                                                <div className="team-hover text-center">
                                                    <i className={(team.genre==="MAN")?"fa fa-male":"fa fa-female"}></i>
                                                    <p>{team.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )

                        })}
                        <div className="clearfix"></div>
                    </div>  
                </div>
            </div>
      </section>
  )
}
